import { defineStore } from "pinia";
import axios from "~/javascript/backend";
import router from "~/javascript/router";
import { usePathStore } from "~/javascript/stores/paths";
import { useOrgStore } from "~/javascript/stores/organizations";
import { emitError, emitSuccess } from "~/javascript/helpers";

const DEFAULT_STATE = {
  orgLocations: [],
  currentOrgLocation: {
    // name:
    // address: {
    //   city:
    //   country:
    //   postal_code:
    //   province:
    //   street_first:
    //   street_second:
    // }
  },
};

export const useOrgLocationStore = defineStore({
  id: "org_locations",

  state: () => ({
    ...structuredClone(DEFAULT_STATE),
    currentOrgLocation: {},
  }),

  getters: {
    currentOrg() {
      const orgStore = useOrgStore();
      return orgStore.currentOrg;
    },

    pathStore() {
      const store = usePathStore();
      return store;
    },

    orgLocationsPath() {
      if (!this.currentOrg) return;
      return `/organizations/${this.currentOrg.id}/org_locations`;
    },
  },

  actions: {
    resetState() {
      this.$state = structuredClone(DEFAULT_STATE);
    },

    setCurrentOrgLocation(orgLocation) {
      this.currentOrgLocation = orgLocation;
    },

    loadOrgLocation(id) {
      axios.get(`${this.orgLocationsPath}/${id}`).then((response) => {
        this.showOrgLocation = response.data;
        this.setCurrentOrgLocation(response.data);
      });
    },

    loadOrgLocations() {
      axios.get(this.orgLocationsPath).then((response) => {
        this.orgLocations = response.data;
      });
    },

    delete(id) {
      axios
        .delete(`${this.orgLocationsPath}/${id}`)
        .then((response) => {
          router.push(this.pathStore.orgLocationsPath);
          emitSuccess(response.data.message);
        })
        .catch((error) => {
          emitError(error.response.data.errors);
        });
    },

    update({ location, id }) {
      axios
        .put(`${this.orgLocationsPath}/${id}`, { org_location: location })
        .then((response) => {
          this.setCurrentOrgLocation(response.data);
          emitSuccess("Org Location updated");
        });
    },

    create(location) {
      axios
        .post(this.orgLocationsPath, { org_location: location })
        .then((response) => {
          this.orgLocations.push(response.data);
          this.setCurrentOrgLocation(response.data);
          router.push(
            `${this.pathStore.orgLocationEditPath(response.data.id)}`
          );
          emitSuccess("Org Location created");
        })
        .catch((error) => {
          emitError(error.response.data.errors);
        });
    },
  },
});
