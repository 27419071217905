import { defineStore } from "pinia";
import axios from "~/javascript/backend";
import router from "~/javascript/router";
import { useApiPathStore } from "~/javascript/stores/api_paths";
import { usePathStore } from "~/javascript/stores/paths";
import { useWebsiteStore } from "~/javascript/stores/websites";
import { emitError, emitSuccess } from "~/javascript/helpers";

const DEFAULT_STATE = {
  currentMediaSchedule: {},
};

export const useMediaScheduleStore = defineStore({
  id: "media_schedules",

  state: () => ({
    ...structuredClone(DEFAULT_STATE),
  }),

  getters: {
    apiPathStore() {
      return useApiPathStore();
    },

    currentWebsite() {
      return useWebsiteStore().currentWebsite;
    },

    pathStore() {
      return usePathStore();
    },

    mediaSchedulesPath() {
      return useApiPathStore().mediaSchedulesPath;
    },
  },

  actions: {
    resetState() {
      this.$state = structuredClone(DEFAULT_STATE);
    },

    setCurrentMediaSchedule(schedule) {
      this.currentMediaSchedule = schedule;
    },

    async loadMediaSchedule(id) {
      return new Promise((resolve) => {
        axios.get(`${this.mediaSchedulesPath}/${id}`).then((response) => {
          this.setCurrentMediaSchedule(response.data);
          resolve();
        });
      });
    },

    update({ mediaScheduleObj, id }) {
      axios
        .put(`${this.mediaSchedulesPath}/${id}`, {
          media_schedule: mediaScheduleObj,
        })
        .then((response) => {
          this.setCurrentMediaSchedule(response.data);
          emitSuccess("Schedule updated");
        })
        .catch((error) => {
          emitError(error.response.data.errors);
        });
    },

    create(mediaScheduleObj) {
      axios
        .post(this.mediaSchedulesPath, { media_schedule: mediaScheduleObj })
        .then((response) => {
          this.setCurrentMediaSchedule(response.data);
          router.push(
            `${this.pathStore.mediaScheduleEditPath(response.data.id)}`,
          );
          emitSuccess("Schedule created");
        })
        .catch((error) => {
          emitError(error);
        });
    },

    delete(id) {
      axios
        .delete(`${this.mediaSchedulesPath}/${id}`)
        .then((response) => {
          router.push(this.pathStore.mediaSchedulesPath);
          emitSuccess(response.data.message);
        })
        .catch((error) => {
          emitError(error.response.data.errors);
        });
    },

    fetchTimeZoneOptions() {
      return axios
        .get(this.apiPathStore.mediaSchedulesTimeZonesPath)
        .then((response) => {
          return response.data.options;
        });
    },
  },
});
