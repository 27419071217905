export const SCOPE_USERS = "users";
export const SCOPE_ORGANIZATIONS = "organizations";
export const SCOPE_WEBSITES = "websites";
export const SCOPE_MEDIA_SCHEDULES = "media_schedules";

export const ACTION_CREATE = "create";
export const ACTION_UPDATE = "update";
export const ACTION_DESTROY = "destroy";
export const ACTION_INDEX = "index";
export const ACTION_SHOW = "show";
export const ACTION_EDIT = "edit";
export const ACTION_NEW = "new";

// TODO: implement across all routes & scopes
export const can = (user, action, scope, targetId = null) => {
  if (!user) return false;
  if (!scope) return false;
  if (!action) return false;
  if (!user.permissions) return false;
  if (!user.permissions[scope]) return false;

  // Users can always edit their own profile
  if (scope === SCOPE_USERS && targetId && user.id === targetId) {
    return true;
  }

  return user.permissions[scope][action];
};
