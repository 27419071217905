// utility functions that don't touch state

export const BS_VAR_DANGER = "danger";
export const BS_VAR_INFO = "info";
export const BS_VAR_PRIMARY = "primary";
export const BS_VAR_SECONDARY = "secondary";
export const BS_VAR_SUCCESS = "success";
export const BS_VAR_WARNING = "warning";
export const BS_VAR_PRIMARY_OUTLINE = "outline-primary";

export const kebabCase = (str) => {
  if (!str) return;
  return str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .join("-")
    .toLowerCase();
};

export const debounce = (func, delay, { leading } = {}) => {
  let timerId;
  return (...args) => {
    if (!timerId && leading) func(...args);
    clearTimeout(timerId);
    timerId = setTimeout(() => func(...args), delay);
  };
};

export const capitalize = (str) => {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
};

export const isDesktop = () => {
  return window.innerWidth > 1200;
};

export const zonelessDate = (d) => {
  const date = d.toDateString();
  const time = d.toTimeString().split(" ")[0];
  return `${date} ${time}`;
};

// n is the number of days ago
export const relativeDate = (n) => {
  const today = new Date();
  return new Date(new Date().setDate(today.getDate() - n));
}

export const isEmpty = (obj) => {
  return obj && Object.keys(obj).length === 0;
}