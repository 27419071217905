// helper functions that touch the vue/pinia layer

import { useOrgStore } from "~/javascript/stores/organizations";
import { useUserStore } from "~/javascript/stores/users";
import { useWorkspaceStore } from "~/javascript/stores/workspaces";
import { useFlashMessageStore } from "~/javascript/stores/flash_messages";
import { BS_VAR_DANGER, BS_VAR_SUCCESS } from "~/javascript/utilities";

export const clearStores = () => {
  const orgStore = useOrgStore();
  const userStore = useUserStore();
  const workspaceStore = useWorkspaceStore();

  orgStore.resetState();
  userStore.resetState();
  workspaceStore.resetState();
};

export const clearLocalStorage = () => {
  localStorage.removeItem("current_org");
  localStorage.removeItem("current_user");
  localStorage.removeItem("current_user_email");
  localStorage.removeItem("current_workspace");
  localStorage.removeItem("token");
};

export const expireSession = () => {
  clearLocalStorage();
  clearStores();
  if (window.location.pathname !== "/login") {
    localStorage.setItem("return_url", window.location.pathname);
  }
  if (window.location.href.indexOf("login") < 0) {
    window.location.href = "/login";
  }
};

const emitMsg = ({ msg, variant, timeout }) => {
  useFlashMessageStore().pushMessage(msg, timeout, variant);
};

export const emitError = (msg, timeout = 5) => {
  emitMsg({ msg, variant: BS_VAR_DANGER, timeout });
};

export const emitSuccess = (msg, timeout = 5) => {
  emitMsg({ msg, variant: BS_VAR_SUCCESS, timeout });
};
