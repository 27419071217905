import { defineStore } from "pinia";
import { BS_VAR_SUCCESS } from "~/javascript/utilities";

export const useFlashMessageStore = defineStore({
  id: "flash_messages",
  state: () => ({
    messages: {},
  }),
  actions: {
    pushMessage(message, timeout = 5, variant = BS_VAR_SUCCESS) {
      const key = crypto.randomUUID();
      this.messages[key] = { msg: message, variant, key };

      if (timeout > 0) {
        setTimeout(() => {
          this.clearMessage(key);
        }, timeout * 1000);
      }
    },

    clearMessage(key) {
      const messages = { ...this.messages };
      delete messages[key];

      this.messages = messages;
    },
  },
});
