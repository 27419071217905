import { defineStore } from "pinia";
import axios from "~/javascript/backend";
import router from "~/javascript/router";
import { usePathStore } from "~/javascript/stores/paths";
import { useOrgStore } from "~/javascript/stores/organizations";
import { emitError, emitSuccess } from "~/javascript/helpers";

const DEFAULT_STATE = {
  industryOptions: [],
  websites: [],
  currentWebsite: null,
};

export const useWebsiteStore = defineStore({
  id: "websites",

  state: () => ({
    ...structuredClone(DEFAULT_STATE),
  }),

  getters: {
    currentOrg() {
      const orgStore = useOrgStore();
      return orgStore.currentOrg;
    },

    pathStore() {
      const store = usePathStore();
      return store;
    },

    currentSitePath() {
      if (!this.currentWebsite) return;
      return `${this.websitesPath}/${this.currentWebsite.id}`;
    },

    websitesPath() {
      return `/organizations/${this.currentOrg?.id}/websites`;
    },

    industryOptionsPath() {
      return `${this.websitesPath}/industry_options`;
    },
  },

  actions: {
    resetState() {
      this.$state = structuredClone(DEFAULT_STATE);
    },

    setCurrentWebsite(website) {
      this.currentWebsite = website;
    },

    async loadWebsite(id) {
      return new Promise((resolve) => {
        axios.get(`${this.websitesPath}/${id}`).then((response) => {
          this.showWebsite = response.data;
          this.setCurrentWebsite(response.data);
          resolve();
        });
      });
    },

    loadIndustryOptions() {
      if (this.industryOptions.length) return;

      axios.get(this.industryOptionsPath).then((response) => {
        this.industryOptions = response.data;
      });
    },

    loadWebsites() {
      axios.get(this.websitesPath).then((response) => {
        this.websites = response.data;
      });
    },

    delete(id) {
      axios
        .delete(`${this.websitesPath}/${id}`)
        .then((response) => {
          router.push(this.pathStore.websitesPath);
          emitSuccess(response.data.message);
        })
        .catch((error) => {
          emitError(error.response.data.errors);
        });
    },

    update({ websiteObj, id }) {
      axios.put(`${this.websitesPath}/${id}`, websiteObj).then((response) => {
        this.setCurrentWebsite(response.data);
        emitSuccess("Website updated");
      });
    },

    create(websiteObj) {
      axios
        .post(this.websitesPath, websiteObj)
        .then((response) => {
          this.websites.push(response.data);
          this.setCurrentWebsite(response.data);
          router.push(`${this.pathStore.websiteEditPath(response.data.id)}`);
          emitSuccess("Website created");
        })
        .catch((error) => {
          emitError(error.response.data.errors);
        });
    },
  },
});
