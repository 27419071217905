import { defineStore } from "pinia";
import axios from "~/javascript/backend";
import router from "~/javascript/router";
import { usePathStore } from "~/javascript/stores/paths";
import { useOrgStore } from "~/javascript/stores/organizations";
import { emitError, emitSuccess } from "~/javascript/helpers";

const DEFAULT_STATE = {
  contacts: [],
  currentContact: {},
};

export const useContactStore = defineStore({
  id: "contacts",

  state: () => ({
    ...structuredClone(DEFAULT_STATE),
    currentContact: {},
  }),

  getters: {
    currentOrg() {
      const orgStore = useOrgStore();
      return orgStore.currentOrg;
    },

    pathStore() {
      const store = usePathStore();
      return store;
    },

    contactsPath() {
      return `/organizations/${this.currentOrg?.id}/contacts`;
    },
  },

  actions: {
    resetState() {
      this.$state = structuredClone(DEFAULT_STATE);
    },

    setCurrentContact(contact) {
      this.currentContact = contact;
    },

    async loadContact(id) {
      return new Promise((resolve) => {
        axios.get(`${this.contactsPath}/${id}`).then((response) => {
          this.setCurrentContact(response.data);
          resolve();
        });
      });
    },

    loadContacts() {
      axios.get(this.contactsPath).then((response) => {
        this.contacts = response.data;
      });
    },

    update({ contactObj, id }) {
      axios.put(`${this.contactsPath}/${id}`, contactObj).then((response) => {
        this.setCurrentContact(response.data);
        emitSuccess("Contact updated");
      });
    },

    create(contactObj) {
      axios
        .post(this.contactsPath, contactObj)
        .then((response) => {
          this.contacts.push(response.data);
          this.setCurrentContact(response.data);
          router.push(`${this.pathStore.contactEditPath(response.data.id)}`);
          emitSuccess("Contact created");
        })
        .catch((error) => {
          emitError(error.response.data.errors);
        });
    },
  },
});
