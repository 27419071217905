import { defineStore } from "pinia";
import axios from "~/javascript/backend";
import router from "~/javascript/router";
import { usePathStore } from "~/javascript/stores/paths";
import { useApiPathStore } from "~/javascript/stores/api_paths";
import { emitError, emitSuccess } from "~/javascript/helpers";

const LOCAL_STORAGE_KEY = "current_org";

const DEFAULT_STATE = {
  organizations: [],
  currentOrg: {},
  showOrg: {},
};

export const useOrgStore = defineStore({
  id: "organizations",

  state: () => ({
    ...structuredClone(DEFAULT_STATE),
    currentOrg: JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)),
  }),

  getters: {
    apiPathStore() {
      return useApiPathStore();
    },
  },

  actions: {
    resetState() {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      this.$state = structuredClone(DEFAULT_STATE);
    },

    setCurrentOrg(org) {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(org));
      this.currentOrg = org;
    },

    async loadOrg(id) {
      return new Promise((resolve) => {
        axios.get(this.apiPathStore.orgPath(id)).then((response) => {
          this.showOrg = response.data;
          this.setCurrentOrg(response.data);
          resolve();
        });
      });
    },

    async loadByIdentifier(identifier) {
      return new Promise((resolve) => {
        axios
          .get(`${this.apiPathStore.orgsPath}/by_identifier/${identifier}`)
          .then((response) => {
            this.setCurrentOrg(response.data);
            resolve();
          });
      });
    },

    loadOrgs() {
      axios.get(this.apiPathStore.orgsPath).then((response) => {
        this.organizations = response.data;

        if (!this.currentOrg?.id) return;

        const ids = response.data.map((o) => o.id);
        if (!ids.includes(this.currentOrg.id)) {
          localStorage.removeItem(LOCAL_STORAGE_KEY);
          this.currentOrg = {};
        }
      });
    },

    update(org) {
      const orgObj = {
        name: org.name,
        identifier: org.identifier,
      };
      axios.put(this.apiPathStore.orgPath(id), orgObj).then((response) => {
        this.showOrg = response.data;
        emitSuccess("Organization updated");
      });
    },

    createOrg({ name, identifier, workspaceId }) {
      const pathStore = usePathStore();
      const body = {
        organization: {
          name,
          identifier,
        },
        workspace_id: workspaceId,
      };
      axios
        .post(this.apiPathStore.orgsPath, body)
        .then((response) => {
          this.organizations.push(response.data);
          router.push(`${pathStore.orgEditPath(response.data.id)}`);
          emitSuccess("Organization created");
        })
        .catch(function (error) {
          emitError(error.response.data.errors);
        });
    },
  },
});
