import { defineStore } from "pinia";
import { adminAxios as axios } from "~/javascript/backend";
import router from "~/javascript/router";
import { useAdminPathStore } from "~/javascript/stores/admin/paths";
import { emitError, emitSuccess } from "~/javascript/helpers";

const DEFAULT_STATE = {
  subscriptionPlans: [],
  currentSubscriptionPlan: {},
};

export const useAdminSubscriptionPlanStore = defineStore({
  id: "subscription_plans",

  state: () => ({
    ...structuredClone(DEFAULT_STATE),
  }),

  getters: {
    adminPathStore() {
      const store = useAdminPathStore();
      return store;
    },

    subscriptionPlansPath() {
      return `/subscription_plans`;
    },
  },

  actions: {
    resetState() {
      this.$state = structuredClone(DEFAULT_STATE);
    },

    setCurrentSubscriptionPlan(subscriptionPlan) {
      this.currentSubscriptionPlan = subscriptionPlan;
    },

    loadSubscriptionPlan(id) {
      axios.get(`${this.subscriptionPlansPath}/${id}`).then((response) => {
        this.setCurrentSubscriptionPlan(response.data);
      });
    },

    loadSubscriptionPlans() {
      axios.get(this.subscriptionPlansPath).then((response) => {
        this.subscriptionPlans = response.data;
      });
    },

    delete(id) {
      axios
        .delete(`${this.subscriptionPlansPath}/${id}`)
        .then((response) => {
          router.push(this.adminPathStore.subscriptionPlansPath);
          emitSuccess(response.data.message);
        })
        .catch((error) => {
          emitError(error.response.data.errors);
        });
    },

    update({ plan, id }) {
      axios
        .put(`${this.subscriptionPlansPath}/${id}`, {
          subscription_plan: plan,
        })
        .then((response) => {
          this.setCurrentSubscriptionPlan(response.data);
          emitSuccess("Subscription Plan updated");
        });
    },

    create(plan) {
      axios
        .post(this.subscriptionPlansPath, { subscription_plan: plan })
        .then((response) => {
          this.subscriptionPlans.push(response.data);
          this.setCurrentSubscriptionPlan(response.data);
          router.push(
            `${this.adminPathStore.subscriptionPlanEditPath(response.data.id)}`
          );
          emitSuccess("Subscription plan created");
        })
        .catch((error) => {
          emitError(error.response.data.errors);
        });
    },
  },
});
