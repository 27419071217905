import { defineStore } from "pinia";
import axios from "~/javascript/backend";
import { useAuthStore } from "~/javascript/stores/auth";
import router from "~/javascript/router";
import { usePathStore } from "~/javascript/stores/paths";
import { emitError, emitSuccess } from "~/javascript/helpers";

const DEFAULT_STATE = {
  users: [],
  shownUser: {},
};

export const useUserStore = defineStore({
  id: "users",

  state: () => ({
    ...structuredClone(DEFAULT_STATE),
  }),

  getters: {
    usersCount(state) {
      return state.users.length;
    },
  },

  actions: {
    resetState() {
      this.$state = structuredClone(DEFAULT_STATE);
    },

    create(userObj) {
      const body = { user: userObj };
      axios
        .post("/users", body)
        .then((response) => {
          this.shownUser = response.data;
          router.push(usePathStore().usersPath);
          emitSuccess("User created");
        })
        .catch((error) => {
          emitError(error.response.data.errors);
        });
    },

    update(user) {
      const authStore = useAuthStore();
      axios
        .put(`/users/${user.id}`, { user: user })
        .then((response) => {
          emitSuccess("User updated");
          if (authStore.currentUser.id === user.id) {
            authStore.updateCurrentUser(response.data);
          }
          this.shownUser = response.data;
        })
        .catch(function (error) {
          emitError(error.response.data.errors);
        });
    },
    loadUsers() {
      axios.get("/users").then((response) => {
        this.users = response.data;
      });
    },
    loadUser(id) {
      axios.get(`/users/${id}`).then((response) => {
        this.shownUser = response.data;
      });
    },
    addUser(user) {
      axios.post("/users", user).then((response) => {
        this.users.push(response.data);
      });
    },
    deleteUser(id) {
      axios.delete("/users/" + id).then((response) => {
        this.users = this.users.filter((object) => {
          return object.id !== id;
        });
      });
    },
  },
});
