import { createRouter, createWebHistory } from "vue-router";

import { adminRoutes } from "~/javascript/router/admin/admin_routes";
import { authRoutes } from "~/javascript/router/auth_routes";
import { workspaceSetupRoutes } from "~/javascript/router/workspace_setup_routes";
import { workspaceGeneralRoutes } from "~/javascript/router/workspace_general_routes";
import { orgsRoutes } from "~/javascript/router/orgs_routes";
import { targetZonesRoutes } from "~/javascript/router/target_zones_routes";
import { targetZipsRoutes } from "~/javascript/router/target_zips_routes";
import { userRoutes } from "~/javascript/router/user_routes";
import {
  clearLocalStorage,
  clearStores,
  emitError,
} from "~/javascript/helpers";
import { useAuthStore } from "~/javascript/stores/auth";
import { useWorkspaceStore } from "~/javascript/stores/workspaces";
import { useRootStore } from "~/javascript/stores/root";
import { useOrgStore } from "~/javascript/stores/organizations";
import { can } from "~/javascript/can";

// route level code-splitting
// this generates a separate chunk (Map.[hash].js) for this route
// which is lazy-loaded when the route is visited.
const AdminApplicationView = () =>
  import("~/javascript/views/admin/AdminApplicationView.vue");
const ApplicationView = () => import("~/javascript/views/ApplicationView.vue");
const LayoutView = () => import("~/javascript/views/workspace/LayoutView.vue");

const redirectToLogin = (to, next) => {
  clearLocalStorage();
  clearStores();
  useAuthStore().setReturnUrl(to.fullPath);
  next({ name: "Login" });
  return;
};

const authUser = async (to, from, next) => {
  const authStore = useAuthStore();
  const currentUser = authStore.getCurrentUser;

  if ((!localStorage.token || !localStorage.current_user_email) && !(window.location.pathname == "/login")) {
    return redirectToLogin(to, next);
  }

  if (!currentUser && localStorage.token && localStorage.current_user_email) {
    const response = await authStore.checkForCurrentSession();

    if (response.sessionFound) {
      next();
      return;
    } else {
      return redirectToLogin(to, next);
    }
  }

  if (to.path.includes("/admin")) {
    next({ name: "Login" });
    return;
  }

  next();
};

const authAdmin = async (to, from, next) => {
  const authStore = useAuthStore();
  const currentUser = authStore.getCurrentUser;

  const orgStore = useOrgStore();
  const workspaceStore = useWorkspaceStore();

  orgStore.resetState();
  workspaceStore.resetState();

  if (
    (!localStorage.token || !localStorage.current_user_email) &&
    !(window.location.pathname == "/login")
  ) {
    return redirectToLogin(to, next);
  }

  if (!currentUser && localStorage.token && localStorage.current_user_email) {
    const response = await authStore.checkForCurrentSession();

    if (response.sessionFound && response.currentUser.global_admin) {
      if (to.path == "/admin") {
        next({ path: "/admin/dashboard" });
        return;
      } else {
        next();
        return;
      }
    } else {
      return redirectToLogin(to, next);
    }
  }

  if (currentUser && currentUser.global_admin) {
    next();
    return;
  }

  next({ name: "Login" });
};

const setCurrentWorkspace = async (to, from, next) => {
  const store = useWorkspaceStore();
  if (store.currentWorkspace?.identifier !== to.params.workspace_identifier) {
    await store.loadWorkspaceByIdentifier(to.params.workspace_identifier);
  }
  next();
};

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      redirect: "/login",
    },
    ...authRoutes,
    {
      path: "/workspace",
      name: "Workspace",
      component: LayoutView,
      beforeEnter: authUser,
      children: workspaceSetupRoutes,
    },
    {
      path: "/admin",
      name: "Admin",
      component: AdminApplicationView,
      beforeEnter: authAdmin,
      children: adminRoutes,
    },
    {
      path: "/:workspace_identifier",
      component: ApplicationView,
      beforeEnter: [authUser, setCurrentWorkspace],
      children: [
        ...orgsRoutes,
        ...targetZipsRoutes,
        ...targetZonesRoutes,
        ...userRoutes,
        ...workspaceGeneralRoutes,
        // TODO: workspace settings
        // TODO: departments, boards, etc.
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  useRootStore().toggleLoading();

  if (to.meta.rbac) {
    console.log("useRbac");

    const authStore = useAuthStore();
    const currentUser = authStore.getCurrentUser;

    const { action, scope } = to.meta.rbac;
    const targetId = parseInt(to.params.user_id);

    // TODO: handle missing currentUser
    if (!can(currentUser, action, scope, targetId)) {
      const currentWorkspace = useWorkspaceStore().currentWorkspace;
      emitError("You are not authorized to access this resource.");
      next({ path: `/${currentWorkspace.identifier}/dashboard` });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach((to, from, failure) => {
  setTimeout(() => {
    useRootStore().toggleLoading(false);
  }, 1000);
  

  if (failure) {
    console.log("router failure: ");
    console.log(to, from, failure);
  }
});

export default router;
