import { usePurchaseStore } from "~/javascript/stores/purchases";

const PurchasesUpload = () =>
  import("~/javascript/views/websites/purchases/PurchasesUpload.vue");
const PurchasesIndex = () =>
  import("~/javascript/views/websites/purchases/PurchasesIndex.vue");
const PurchasesShow = () =>
  import("~/javascript/views/websites/purchases/PurchasesShow.vue");

const setCurrentPurchase = (to, from, next) => {
  const store = usePurchaseStore();
  if (store.currentWebsite?.id) {
    store.loadPurchase(to.params.purchase_id);
    next();
  } else {
    setTimeout(() => setCurrentPurchase(to, from, next), 100);
  }
};

export const purchasesRoutes = [
  {
    path: "purchases",
    name: "Purchases",
    children: [
      {
        path: "",
        component: PurchasesIndex,
      },
      {
        path: "upload",
        name: "Bulk Import Purchases",
        component: PurchasesUpload,
      },
      {
        path: ":purchase_id",
        name: "Purchase Details",
        beforeEnter: setCurrentPurchase,
        children: [
          {
            path: "",
            component: PurchasesShow,
          },
        ],
      },
    ],
  },
];
