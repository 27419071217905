const AuthBaseView = () => import("~/javascript/views/auth/AuthBaseView.vue");
const ForgotPasswordView = () =>
  import("~/javascript/views/auth/ForgotPasswordView.vue");
const LoginView = () => import("~/javascript/views/auth/LoginView.vue");
const PasswordEditView = () =>
  import("~/javascript/views/auth/PasswordEditView.vue");
const RegisterView = () => import("~/javascript/views/auth/RegisterView.vue");
const VerifyEmailView = () =>
  import("~/javascript/views/auth/VerifyEmailView.vue");
const InviteRegisterView = () =>
  import("~/javascript/views/auth/InviteRegisterView.vue");

export const authRoutes = [
  {
    path: "/login",
    name: "AuthBase",
    component: AuthBaseView,
    children: [
      {
        path: "",
        name: "Login",
        component: LoginView,
      },
      {
        path: "/register",
        name: "Register",
        component: RegisterView,
      },
      {
        path: "/verifyemail",
        name: "VerifyEmail",
        component: VerifyEmailView,
      },
      {
        path: "/forgotpassword",
        name: "ForgotPassword",
        component: ForgotPasswordView,
      },
      {
        path: "/users/password/edit",
        name: "PasswordReset",
        component: PasswordEditView,
      },
      {
        path: "/invite",
        name: "InviteRegister",
        component: InviteRegisterView,
      },
    ],
  },
];
