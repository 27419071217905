const DashboardView = () => import("~/javascript/views/DashboardView.vue");
const StyleGuide = () => import("~/javascript/views/StyleGuide.vue");
const UserSettings = () => import("~/javascript/views/UserSettings.vue");
const AllWebsitesIndex = () =>
  import("~/javascript/views/websites/AllWebsitesIndex.vue");

export const workspaceGeneralRoutes = [
  {
    path: "dashboard",
    component: DashboardView,
  },
  {
    path: "user_settings",
    name: "User Settings",
    meta: { subtitle: "Manage your account" },
    component: UserSettings,
  },
  {
    path: "style_guide",
    name: "StyleGuide",
    component: StyleGuide,
  },
  {
    path: "all_websites",
    name: "All Websites",
    component: AllWebsitesIndex,
  },
];
