import { defineStore } from "pinia";
import { deviseAxios } from "~/javascript/backend";
import router from "~/javascript/router";
import {
  clearLocalStorage,
  clearStores,
  emitError,
  emitSuccess,
} from "~/javascript/helpers";

export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    currentUser: null,
    returnUrl: null,
  }),

  getters: {
    getCurrentUser(state) {
      return state.currentUser;
    },

    getReturnUrl() {
      const url = this.returnUrl || localStorage.getItem("return_url");

      if (!url || url.includes("undefined") || url.includes("null")) return;

      return url;
    },
  },

  actions: {
    resetPassword(data) {
      const userData = { user: data };
      deviseAxios.put("/users/password", userData).then(function (response) {
        emitSuccess("Password updated. Redirecting to login page ... ");
        setTimeout(function () {
          router.push("/dashboard");
        }, 3000);
      });
    },

    forgotPassword(email) {
      const data = {
        user: { email: email },
      };
      deviseAxios.post("/users/password", data).then(function (response) {
        emitSuccess("Check your email for the reset password instructions");
      }).catch(function (error) {
        emitError(error.response.data.errors);
      });
    },

    setCurrentUser(response) {
      const token = response.headers.get("Authorization").split(" ")[1];
      localStorage.setItem("token", token);
      this.updateCurrentUser(response.data);
    },

    register(userData) {
      const userObject = { user: userData };
      deviseAxios
        .post("/users", userObject)
        .then((response) => {
          if (response && response.status === 200) {
            router.push(`/verifyemail?email=${userData.email}`);
          }
        })
        .catch(function (error) {
          emitError(error.response.data.errors);
        });
    },

    verifyEmail(token) {
      const url = "/users/confirmation?confirmation_token=" + token;
      deviseAxios
        .get(url)
        .then((response) => {
          if (response && response.status === 200) {
            const store = useAuthStore();
            store.setCurrentUser(response);
            router.push("/workspace/setup");
          }
        })
        .catch(function (error) {
          emitError(error.response.data.errors);
        });
    },

    login(userData) {
      const userObject = { user: userData };

      deviseAxios.post("/users/login", userObject).then((response) => {
        if (response && response.status === 200) {
          if (
            response.data.message &&
            response.data.message === "User not confirmed"
          ) {
            return router.push(`/verifyemail?email=${response.data.email}`);
          }

          const store = useAuthStore();
          store.setCurrentUser(response);

          if (response.data.workspaces.length === 0) {
            return router.push("/workspace/create");
          }

          if (this.getReturnUrl) {
            const route = router.resolve(this.getReturnUrl);
            const workspaceId = route.params.workspace_identifier;
            const result = workspaceId
              ? `/workspace/preload/${workspaceId}`
              : "/workspace/select";
            router.push(result);
          } else {
            router.push("/workspace/select");
          }
        } else {
          emitError(response.data.errors || "Invalid credentials");
        }
      });
    },

    updateCurrentUser(userData) {
      userData.email_domain = userData.email.split("@")[1];
      localStorage.setItem(
        "current_user_email",
        JSON.stringify(userData.email),
      );
      this.currentUser = userData;
    },

    private() {
      deviseAxios.get("/private").then(function (response) {
        console.log(response.data);
      });
    },

    logout() {
      const self = this;
      deviseAxios
        .delete("/users/logout")
        .then(function (response) {
          setTimeout(function () {
            clearLocalStorage();
            clearStores();
            self.currentUser = null;
          }, 200);
          router.push("/login");
        })
        .catch(function (error) {
          console.log(error?.response?.data?.error);
        });
    },

    setReturnUrl(url) {
      this.returnUrl = url;
    },

    async checkForCurrentSession() {
      return deviseAxios
        .post("/users/check_session")
        .then((response) => {
          if (response && response.status === 200) {
            this.updateCurrentUser(response.data);
            return { sessionFound: true, currentUser: response.data };
          }
        })
        .catch((error) => {
          clearLocalStorage();
          clearStores();
          this.currentUser = null;
          console.log(error);
          return { sessionFound: false };
        });
    },

    async resendConfirmation(email) {
      deviseAxios
        .post("/users/resend_confirmation", { email })
        .then((response) => {
          if (response && response.status === 200) {
            emitSuccess("Confirmation email resent");
          } else {
            emitError("Error resending confirmation email");
          }
        })
        .catch((error) => {
          emitError(error);
        });
    },

    async validateInvite(userToken, workspaceToken) {

      const invalidResponse = { valid: false, error: "Invalid invite token" };
      return deviseAxios
        .post(`/users/validate_invite`, {
          invite_token: userToken,
          workspace_token: workspaceToken,
        })
        .then((response) => {
          if (response && response.status === 200) {
            return {
              valid: true,
              email: response.data.email,
              workspace: response.data.workspace,
            };
          } else {
            return invalidResponse;
          }
        })
        .catch((error) => {
          return invalidResponse;
        });
    },

    registerInvite(userData) {
      deviseAxios
        .post("/users/register_invite", userData)
        .then((response) => {
          if (response && response.status === 200) {
            emitSuccess("Account created successfully. Redirecting to login page ...");
            setTimeout(() => {
              router.push("/login");
            }, 3000);
          } else {
            emitError(response.data.errors);
          }
        })
        .catch((error) => {
          emitError(error);
        });
    },
  },
});
