import { defineStore } from "pinia";
import axios from "~/javascript/backend";
import router from "~/javascript/router";
import { usePathStore } from "~/javascript/stores/paths";
import { useWebsiteStore } from "~/javascript/stores/websites";
import { emitError, emitSuccess } from "~/javascript/helpers";

const DEFAULT_STATE = {
  trackingTags: [],
  currentTrackingTag: {
    // name
    // script_content
    // status
    // rocket_tag_id
    // tag_container_id
    // published_version_id
    // draft_id
  },
  loadingTag: false,
};

export const useTrackingTagStore = defineStore({
  id: "tracking_tags",

  state: () => ({
    ...structuredClone(DEFAULT_STATE),
  }),

  getters: {
    currentWebsite() {
      return this.websiteStore.currentWebsite;
    },

    pathStore() {
      const store = usePathStore();
      return store;
    },

    websiteStore() {
      return useWebsiteStore();
    },

    tagContainerPath() {
      return `${this.websiteStore.currentSitePath}/tag_container`;
    },

    trackingTagsPath() {
      return `${this.websiteStore.currentSitePath}/tracking_tags`;
    },
  },

  actions: {
    resetState() {
      this.$state = structuredClone(DEFAULT_STATE);
    },

    setCurrentTrackingTag(trackingTag) {
      this.currentTrackingTag = trackingTag;
    },

    loadTrackingTag(id) {
      this.loadingTag = true;
      axios.get(`${this.trackingTagsPath}/${id}`).then((response) => {
        this.setCurrentTrackingTag(response.data);
        this.loadingTag = false;
      });
    },

    loadTrackingTags() {
      axios.get(this.trackingTagsPath).then((response) => {
        this.trackingTags = response.data;
      });
    },

    delete(id) {
      axios
        .delete(`${this.trackingTagsPath}/${id}`)
        .then((response) => {
          router.push(this.pathStore.trackingTagsPath);
          emitSuccess(response.data.message);
        })
        .catch((error) => {
          emitError(error.response.data.errors);
        });
    },

    update({ tag, id }) {
      axios
        .put(`${this.trackingTagsPath}/${id}`, { tracking_tag: tag })
        .then((response) => {
          this.setCurrentTrackingTag(response.data);
          router.push(`${this.pathStore.trackingTagPath(response.data.id)}`);
          emitSuccess("Tag updated");
        })
        .catch((error) => emitError(error.response.data.errors));
    },

    create(tag) {
      axios
        .post(this.trackingTagsPath, { tracking_tag: tag })
        .then((response) => {
          this.trackingTags.push(response.data);
          this.setCurrentTrackingTag(response.data);
          router.push(`${this.pathStore.trackingTagPath(response.data.id)}`);
          emitSuccess("Tag created");
        })
        .catch((error) =>
          emitError(error.response?.data?.errors || error.error)
        );
    },
  },
});
